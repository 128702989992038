import React from 'react';

function NoReviews(props){

    return(
        <div className='container'>
            <div className="card">
                <div className="card-body">
                    <p className="card-title">{props.message}</p>
                </div>
            </div>
        </div>
    )
};

export default NoReviews;