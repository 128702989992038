import React from 'react';
import Loading from './Loading'
import Error from './Error';

function Review(props) {
    window.scrollTo(0, 0);

    if(props.error){
        return (
            <div className='App-page'>
                <p />
                <Error />
            </div>
        )
    }

    if (props.loading === true) {
        return (
            <div className='App-page'>
                <p />
                <Loading title={"Let me get that for you"} message={""}/>
            </div>
        )
    }

    const reviewParagraphs = props.fullReview.split("[newline]").map((line, index) => {
        return <p className='card-text fs-6' key={index}>{line}</p>;
    });

    return (
        <div className='container text-start text-white'>
            <div>
                <div className="row g-0">
                    <div className="col-md-4">
                        <img src={props.img} className="img-thumbnail rounded-0" alt="..." />
                    </div>
                    <div className="col-md-8" style={{ paddingLeft: '20px' }}>
                        <div>
                            <p></p>
                            <h5 className="card-title fs-1">{props.title}</h5>
                            <p></p>
                            <p className="card-text fs-5 fst-italic">{props.shortDescription}</p>
                            {reviewParagraphs}
                            <p className="card-text"><small className="text-white">Reviewed on: {props.date}</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Review;