import Header from '../Components/Header';
import Error from '../Components/Error';
function ErrorPage(){

    return(
        <div className="App-page">
            <Header></Header>
            <p></p>
            <Error></Error>
        </div>
    )
}

export default ErrorPage