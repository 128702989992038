import React from 'react';
import { Link} from 'react-router-dom'

function Card(props) {  
  return (
    <div className="customcard">
      <Link to={`/reviews/${props.id}`}>
      <img src={props.img} className="card-img-top custom-img" alt="..."/>
      </Link>
    </div>
  );
}

export default Card;