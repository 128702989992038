import Card from './Card';
import '../App.css';
import GenericErrorCard from './GenericErrorCard';
import Loading from './Loading'

function Content({ reviews, error }) {
  
  if (error) {
    return (
      <div className='App-page'>
        <GenericErrorCard />
      </div>
    );
  }

  if (!reviews || reviews.length === 0) {
    return (
      <div className='App-page'>
        <p />
        <Loading title={"Welcome to Bookifiction"} message={"Just a moment while we fetch the latest reviews."}/>
      </div>
    );
  }

  return (
    <div className="card-deck">
    {reviews
      .sort((a, b) => {
        const dateA = new Date(
          a.date.split("/").reverse().join("-")
        );
        const dateB = new Date(
          b.date.split("/").reverse().join("-")
        );
        return dateB - dateA;
      })
      .map((review) => (
        <Card
          key={review._id}
          id={review._id}
          img={review.imageURL}
          title={review.bookTitle}
          shortDescription={review.headerQuote}
          fullReview={review.review}
        />
      ))}
  </div>
  );
}

export default Content;