import { createTheme } from '@mui/material/styles';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#49745f', // Primary color
    },
    secondary: {
      main: '#DAE4EC', // Secondary color
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#385144', // Header background color
        },
      },
    },
  },
});

export default theme;