export async function getAllReviews(){
    try{
        const response = await fetch('https://us-central1-bookifiction.cloudfunctions.net/api/reviews/getall');
        const data = await response.json();
        return data;
    }catch(error){
        return [];
    }
}

export async function getReviewByID(id){
    try{
        const response = await fetch(`https://us-central1-bookifiction.cloudfunctions.net/api/reviews/getreviewbyid/${id}`); //http://localhost:8080
        
        if (response.status === 404) {
            throw new Error('Review not found');
        }

        const data = await response.json();

        return data;
    }catch(error){
        throw error;
    }
}
