function Loading(props) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <div className="text-center text-light">
          <h2>{props.title}<span className="loading-dots"><span>.</span><span>.</span><span>.</span></span></h2>
          <p>{props.message}</p>
        </div>
      </div>
    )
  }
  
  export default Loading;
  