import React, { useState, useEffect } from 'react';
import Content from '../Components/Content'
import Header from '../Components/Header';
import { getAllReviews } from '../Services/UserServices';

function Home() {
  window.scrollTo(0, 0);
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchReviews() {
      try {
        const data = await getAllReviews();
        setReviews(data);
      } catch (error) {
        setError(true); 
      }
    }
    fetchReviews();
  }, []);

  return (
    <div className='App-page'>
      <Header/>
      <p/>
      <Content reviews={reviews} error={error} />
    </div>
  );
}

export default Home;