import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import { FaGoodreadsG, FaInstagram } from 'react-icons/fa';
import { SiSubstack } from 'react-icons/si'; 

function Header() {
    return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Bookifiction</Link>
          </Typography>
          <IconButton color="inherit" href="https://www.goodreads.com/user/show/11180459-sahar" target="_blank">
            <FaGoodreadsG />
          </IconButton>
          <IconButton color="inherit" href="https://www.instagram.com/bookifiction/" target="_blank">
            <FaInstagram />
          </IconButton>
          <IconButton color="inherit" href="https://bookifiction.substack.com/" target="_blank">
            <SiSubstack />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
    );
}

export default Header;