import React from 'react';
import {Link} from 'react-router-dom';

function Error(){

    return(
        <div className='container'>
            <div className="card">
                <div className="card-body">
                    <p className="card-title">Oops, page not found!</p>
                    <p></p>
                    <Link to='/'>
                    <button type="button" className="btn btn-outline-dark">Go Home</button>
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default Error;