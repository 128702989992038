import Header from '../Components/Header';
import Review from '../Components/Review';
import { useState, useEffect } from 'react';
import { getReviewByID } from '../Services/UserServices';
import { useParams } from 'react-router-dom';

function Reviews() {
  const { id } = useParams();

  const [review, setReview] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchReview() {
      try {
        const data = await getReviewByID(id); 
        setReview(data);
      } catch (error) {
        setError(error);
      }
    }
    fetchReview();
  }, []);

  if (error && error.message === "Review not found") {
    return (
      <div className='App-page'>
        <Header />
        <p />
        <Review error={error}/>
      </div>
    );
  }

  if (!review) {
    return (
      <div className='App-page'>
        <Header />
        <p />
        <Review loading={true}/>
      </div>
    );
  }

  return (
    <div className='App-page'>
      <Header />
      <p />
      <Review
        img={review.imageURL}
        title={review.bookTitle}
        shortDescription={review.headerQuote}
        fullReview={review.review}
        date={review.date}
      />
    </div>
  );
}

export default Reviews;