import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './Components/Theme';
import './App.css';
import Reviews from './Pages/Reviews';
import Home from './Pages/Home';
import ErrorPage from './Pages/ErrorPage';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/reviews/:id" element={<Reviews />} />
            <Route path="/" element={<Home />} />
            <Route path="reviews/" element={<Home />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
